import React, { useState } from "react";
import "./reviewSection.css";

const reviews = [
    {
        name: "Thomas",
        age: 45,
        location: "Dallas, TX",
        rating: 5,
        review: "As a father of two boys and a young daughter, Flotection has been a lifesaver! Now, we don't have to guess when it’s her time of the month. This helps us plan to make sure we're extra supportive and not accidentally adding stress."
    },
    {
        name: "Alex",
        age: 23,
        location: "San Francisco, CA",
        rating: 5,
        review: "I started using Flotection to sync with my girlfriend's cycle, and it's revolutionized our relationship! Being aware of when she needs extra space or support has been invaluable. Plus, we're not ready for kids yet, so the fertility tracker is critical for us to avoid surprises."
    },
    {
        name: "Michael",
        age: 38,
        location: "Chicago, IL",
        rating: 5,
        review: "After 10 years of marriage, I thought I knew my wife inside out. Flotection proved there’s always more to learn. Tracking her cycle has deepened my understanding of her needs. The notifications are fantastic! They remind me to do those little things that make a big difference during her tough days."
    }
];

const ReviewSection = () => {
    const [currentReview, setCurrentReview] = useState(0);

    const handlePrev = () => {
        setCurrentReview((prevIndex) =>
            prevIndex === 0 ? reviews.length - 1 : prevIndex - 1
        );
    };

    const handleNext = () => {
        setCurrentReview((prevIndex) =>
            prevIndex === reviews.length - 1 ? 0 : prevIndex + 1
        );
    };

    const review = reviews[currentReview];

    return (
        <div className="review-section">
            <div className="review-navigation">
                {currentReview !== 0 && (
                    <button className="nav-button" onClick={handlePrev} aria-label="previous review">
                      <span> &#8592;</span>  
                    </button>
                )}
            </div>
            <div className="review-card">
                <div className="stars">
                    {[...Array(5)].map((_, i) => (
                        <span key={i} className={`star ${i < review.rating ? "filled" : ""}`}>★</span>
                    ))}
                </div>
                <p className="review-text">"{review.review}"</p>
                <p className="review-author">- {review.name}, {review.age}, {review.location}</p>
                <p className="beta-version">User Of Beta Version</p>
            </div>
            <div className="review-navigation">
                {currentReview !== reviews.length - 1 && (
                    <button className="nav-button" onClick={handleNext} aria-label="next review">
                       <span>&#8594;</span> 
                    </button>
                )}
            </div>
        </div>
    );
};

export default ReviewSection;
