import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import './style.css';
import img_1 from "../../assets/images/swiperImage/img_1.svg"
import img_2 from "../../assets/images/swiperImage/img_2.svg";
import img_3 from "../../assets/images/swiperImage/img_3.svg";
import img_4 from "../../assets/images/swiperImage/img_4.svg";
import img_5 from "../../assets/images/swiperImage/img_5.svg";
import img_6 from "../../assets/images/swiperImage/img_6.svg";
import img_7 from "../../assets/images/swiperImage/img_7.svg";
import img_8 from "../../assets/images/swiperImage/img_8.svg";
import img_9 from "../../assets/images/swiperImage/img_9.svg";
import img_10 from "../../assets/images/swiperImage/img_10.svg";
import img_11 from "../../assets/images/swiperImage/img_11.svg";
import img_12 from "../../assets/images/swiperImage/img_12.svg";
import { Pagination } from 'swiper/modules';

const CustomSwiper = () => {
  const [slidesPerView, setSlidesPerView] = useState(5);
  const [spaceBetween, setSpaceBetween] = useState(-40);
  const [loadedImages, setLoadedImages] = useState([img_1, img_2, img_3, img_4, img_5]); // Start with 5 images
  const [currentIndex, setCurrentIndex] = useState(5); // Start with the index for the next image to load

  const allImages = [
    img_1, img_2, img_3, img_4, img_5, 
    img_6, img_7, img_8, img_9, img_10, 
    img_11, img_12
  ];

  const handleResize = () => {
    const settings = [
      { maxWidth: 310, slidesPerView: 1, spaceBetween: -120 },
      { maxWidth: 368, slidesPerView: 2, spaceBetween: -20 },
      { maxWidth: 425, slidesPerView: 2, spaceBetween: -50 },
      { maxWidth: 540, slidesPerView: 2, spaceBetween: -100 },
      { maxWidth: 768, slidesPerView: 2, spaceBetween: -260 },
      { maxWidth: 1370, slidesPerView: 3, spaceBetween: -10 },
      { maxWidth: Infinity, slidesPerView: 5, spaceBetween: -40 }
    ];

    const { slidesPerView, spaceBetween } = settings.find(setting => window.innerWidth <= setting.maxWidth);

    setSlidesPerView(slidesPerView);
    setSpaceBetween(spaceBetween);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    // Set initial slidesPerView value
    handleResize();

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const loadMoreSlides = () => {
    if (currentIndex < allImages.length) {
      setLoadedImages([...loadedImages, allImages[currentIndex]]);
      setCurrentIndex(currentIndex + 1);
    }
  };

  return (
    <Swiper
      slidesPerView={slidesPerView}
      centeredSlides={true}
      spaceBetween={spaceBetween}
      pagination={{
        clickable: true,
      }}
      loop={false}
      modules={[Pagination]}
      className="swiper-container swiper-full-mobile"
      onSlideChange={(swiper) => {
        if (swiper.activeIndex >= loadedImages.length - slidesPerView) loadMoreSlides();
      }} // Load one image at a time when reaching the end
    >
      {loadedImages.map((src, index) => (
        <SwiperSlide className='swiper-slide' key={index}>
          <img loading="lazy" src={src} alt={`Slide ${index + 1}`} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default CustomSwiper;
