import brandLogo from "./assets/images/brandLogo.svg";
import welcomeView from "./assets/images/welcomView.svg";
import dorpIcon from "./assets/images/dropIcon.svg";
import backgroundCurve from "./assets/images/backgroundCurve.svg";
import optionBg from "./assets/images/optionBg.svg";
import trackIcon from "./assets/images/trackIcon.svg";
import learnIcon from "./assets/images/learnIcon.svg";
import growIcon from "./assets/images/growIcon.svg";
import menstrualCycle from "./assets/images/menstrualCycle.svg";
import moodIcon from "./assets/images/moodIcon.svg";
import fertelityIcon from "./assets/images/fertelityIcon.svg";
import floatTracking from "./assets/images/floatTracking.svg";
import shareOption from "./assets/images/shareOption.svg";
import secureIcon from "./assets/images/secureIcon.svg";
import whatsupIcon from "./assets/images/whatsupIcon.svg";
import messageIcon from "./assets/images/messageIcon.svg";
import chatIcon from "./assets/images/chatIcon.svg";
import messagnerIcon from "./assets/images/messagnerIcon.svg";
import notification from "./assets/images/notification.svg";
import appStoreIcon from "./assets/images/appStoreIcon.svg";
import playStoreIcon from "./assets/images/playStoreIcon.svg";
import "./App.css";
import SubscribeButton from "./component/subcribeButton";
import { ContactForm } from "./component/contactForm";
import React, { useState } from "react";
import { RxHamburgerMenu } from "react-icons/rx";
import { RiCloseFill } from "react-icons/ri";
import CustomSwiper from "./component/customSwiper";
import ReviewSection from "./component/reviewsSection/reviewSection";

const App = React.memo(() => {
  const blogUrl = process.env.REACT_APP_BLOG_URL;
  const [isactive, setisactive] = useState(false);
  const [successMsgVisible, setSuccessMsgVisible] = useState(false);

  function hamber() {
    setisactive(!isactive);
  }
  const currentYear = new Date().getFullYear();
  const handleClick = () => { }
  return (
    <div className="mainwrapper">
      <div className="header-container">
        <div className="left-header">
          <img loading="lazy" src={brandLogo} alt="period tracker app | menstruation tracker app | menstrual period calendar period calendar" />{" "}
          <h1 className="brand-title">Flotection</h1>
        </div>
        <div className="menu-options">
          <a href="#whyFlotection">Why Flotection</a>
          <a href="#about">About</a>
          <a href="#features">Features</a>
          <a href={blogUrl}>Blogs</a>
          <a href="#download">Download</a>
          <a href="#visual">Visuals</a>
          <a href="#contact">Contact</a>
          <p className="hamburger" onClick={hamber}>
            {!isactive ? (
              <RxHamburgerMenu style={{ fontSize: "27px" }} />
            ) : (
              <RiCloseFill style={{ fontSize: "27px" }} />
            )}
          </p>
        </div>
      </div>

      <div className={isactive ? "ham" : "none"}>
        <div>
          <a href="#whyFlotection">Why Flotection</a>
          <a href="#about">About</a>
          <a href="#features">Features</a>
          <a href={blogUrl}>Blogs</a>
          <a href="#download">Download</a>
          <a href="#visual">Visuals</a>
          <a href="#contact">Contact</a>
        </div>
      </div>


      <div className="flotection-protection">
        <div className="inner-cotainer">
          <div className="left-inner-container">
            <h2 className="protection-head">
              Understanding Her Cycle, Starts with You
            </h2>
            {/* <div className="drop-view">
              <img loading="lazy" className="drop-img" src={dorpIcon} alt="dropIcon" />{" "}
              <b className="review-area">Watch out this space for more information...</b> 
            </div> */}
            <div>
              <ReviewSection />
            </div>
            <div className="subscribe-value">
              <p className="flow-subcription"> Subscribe to get Updates </p>
              <SubscribeButton subcriptionClick={() => handleClick()} successMsgVisible={successMsgVisible} setSuccessMsgVisible={setSuccessMsgVisible} />
            </div>
          </div>
          <div
            className="welcome-mob"
            style={{ zIndex: successMsgVisible ? 0 : 1 }}
          >
            <img
              loading="lazy"
              className="welcome-img"
              src={welcomeView}
              alt="Relieve Menstrual pain | menstrual pain reliever | best pain reliever menstrual cramps"
            />
          </div>

        </div>
        <img loading="lazy" className="curve-background" src={backgroundCurve} />
      </div>
      <br />

      <div id="whyFlotection">
        <div className="about-flotection">
          <h3 className="section-head">Why Flotection?</h3>
          <p className="discript">
            To get tips and guidance on what to do and how to deal with your
            loved ones' cycle.
            <br />
            Many men feel completely lost and don't know what to do when their
            loved ones are experiencing hormonal imbalances.
          </p>
          <p className="sub-head">We are here to help</p>
          <p className="discript">
            We believe that by helping men deal with and understand what their
            loved ones, <br />
            especially women, are going through, we can help men become better
            partners and ultimately strengthen relationships.
          </p>
        </div>
        <div className="flotection-options">
          <div className="loc-card">
            <div className="option-container">
              <img loading="lazy" src={optionBg} />
              <img loading="lazy" src={trackIcon} />
            </div>
            <p className="card-head">Track</p>
            <p className="card-discrip">
              Keep your loved one’s cycle at your fingertips. Our tracking
              calendar gives you insight into her upcoming days.
            </p>
          </div>
          <div className="loc-card">
            <div className="option-container ">
              <img loading="lazy" src={optionBg} />
              <img loading="lazy" src={learnIcon} />
            </div>
            <p className="card-head">Learn</p>
            <p className="card-discrip">
              Gain valuable insights and stay informed! Our app notifies you
              about upcoming stages in your loved one’s cycle. Access articles
              and videos to deepen your understanding of the subject.
            </p>
          </div>
          <div className="loc-card">
            <div className="option-container">
              <img loading="lazy" src={optionBg} />
              <img loading="lazy" src={growIcon} />
            </div>
            <p className="card-head">Grow</p>
            <p className="card-discrip">
              Evolve into a better partner. Navigating a menstrual cycle is no
              easy feat, especially for your partner. Grow into the man she
              needs, not just the man you think she wants.
            </p>
          </div>
        </div>
      </div>
      <div className="flotection-tracking" id="about">
        <div className="inner-tracking">
          <div className="left-inner-track">
            <p className="track-head">
              Flotection's easy-to-use tracking calendar keeps you informed and
              prepared.
            </p>
            <dl style={{ marginTop: "60px" }}>
              <dd className="track-def">
                <img loading="lazy" src={menstrualCycle} alt="Menstrual" />{" "}
                <p className="track-descrip">
                  <span>Menstrual Cycle: </span>Understand and anticipate your
                  loved one's cycle so you can be better prepared.
                </p>
              </dd>
              <dd className="track-def">
                <img loading="lazy" src={moodIcon} alt="Menstrual Mood" />{" "}
                <p className="track-descrip">
                  <span>Mood: </span>Stay attuned to mood changes and provide
                  the right support at the right time.
                </p>
              </dd>
              <dd className="track-def">
                <img loading="lazy" src={fertelityIcon} alt="Fertility and Ovulation Dates" />{" "}
                <p className="track-descrip">
                  <span>Fertility and Ovulation Dates: </span>Plan and navigate
                  fertility-related discussions and events with ease.
                </p>
              </dd>
            </dl>
          </div>
          <div className="track-mob">
            <img loading="lazy" className="track-img"
              src={floatTracking}
              alt="menstrual cycle | cycle of menstrual cycle | menses period cycle | menstrual cycle phases"
            />
          </div>
        </div>
      </div>
      <div className="share-link" id="features">
        <div className="share-mob">
          <img loading="lazy" className="share-img" src={shareOption} alt="remembering loved ones | period tracker | menstrual tracking | period cycle" />
        </div>
        <div className="share-content">
          <div className="left-share-track">
            <p className="share-head">Adding a loved one</p>
            <p className="share-descript">
              When adding someone to track, simply fill out a short form. You
              can either ask the person you want to track for the information or
              send them a link so they can fill it out themselves.
            </p>
            <dl>
              <dd className="share-def">
                <img loading="lazy" src={secureIcon} alt="Secure" />
                <span>Your Loved One's Data will be safe and secure with Flotection.
                  Download and Get Started</span>
              </dd>
            </dl>
            <div className="share-iconContainer">
              <img loading="lazy" className="contact-icon" src={whatsupIcon} alt="WhatsAPP" />
              <img loading="lazy" className="contact-icon messageIcon" src={messageIcon} alt="Message" />
              <img loading="lazy" className="contact-icon" src={chatIcon} alt="Chatter" />
              <img loading="lazy" className="contact-icon" src={messagnerIcon} alt="Messagner" />
            </div>
          </div>
        </div>
      </div>
      <div className="flotection-notifications" id="download">
        <div>
          <p className="notification-head" style={{ marginBottom: "30px" }}>Stay informed with notifications, articles, and videos!</p>
          <dl>
            <dd>
              <span>Notifications: </span>Receive tips and reminders on how to
              deal with or what to expect from your loved one's cycle.
            </dd>
            <dd>
              <span>Learning: </span>Explore Flotection’s home page and find
              health articles and videos on various topics regarding menstrual
              cycles.
            </dd>
          </dl>
          <div className="available-store">
            <img loading="lazy"
              className="appstore-icon"
              src={appStoreIcon}
              alt="App Store"
            />
            <img loading="lazy"
              className="appstore-icon"
              src={playStoreIcon}
              alt="Play Store"
            />
          </div>
        </div>
        <div className="note-mob">
          <img loading="lazy"
            className="note-img"
            src={notification}
            alt="period cravings | food cravings before period | menstruation cravings"
          />
        </div>
      </div>
      <div className="swip-container" id="visual">
        <p className="swip-head">
          Check us out! Swipe through some of <br /> Flotection's screens.
        </p>
        <CustomSwiper />
      </div>
      <div className="form-container" id="contact">
        <p className="form-head" style={{ color: "#fff", padding: "25px 0px 10px 0px" }}>Connect With Us</p>
        <p className="form-descrip">We are happy to help!</p>
        <ContactForm />
      </div>
      <div className="footer-cnr">
        <div className="footer-left">
          <img loading="lazy" src={brandLogo} alt="period tracker app | menstruation tracker app | menstrual period calendar period calendar" />{" "}
          <p className="brand-title">Flotection</p>
        </div>
        <div className="footer-Rightstore">
          <p className="footer-title">Download</p>
          <div className="footer-store">
            <img loading="lazy"
              className="appstore-icon"
              src={appStoreIcon}
              alt="App Store"
            />
            <img loading="lazy"
              className="appstore-icon"
              src={playStoreIcon}
              alt="Play Store"
            />
          </div>
        </div>
      </div>
      <hr />
      <div className="footer-copyRights">
        <div className="copyRight">
          <a className="footer-links">
            © {currentYear} Flotection, All rights reserved
          </a>
        </div>
        <div className="terms-conditions">
          <a className="footer-links">Terms</a>
          <a className="footer-links">Security</a>
          <a className="footer-links">Privacy Policy</a>
        </div>
      </div>
      <div className="footer-mobile">
        <div className="footer-Leftcontainer">
          <img loading="lazy" src={brandLogo} alt="period tracker app | menstruation tracker app | menstrual period calendar period calendar" />{" "}
          <p className="brand-title">Flotection</p>
        </div>
        <a className="footer-links">
          © {currentYear} Flotection, All rights reserved
        </a>
        <div className="footer-Rightcontainer">
          <a className="footer-links">Terms</a>
          <a className="footer-links">Security</a>
          <a className="footer-links">Privacy Policy</a>
        </div>
      </div>
    </div>
  );
})

export default App;
