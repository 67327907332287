import React, { useState } from 'react';
import './style.css'; // Assuming you're adding the styles in this CSS file

const ZohoCampaignOptinForm = () => {
  const [signupSuccess, setSignupSuccess] = useState(false); // Track signup status
  const [buttonText, setButtonText] = useState('Subscribe'); // Track button text

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;

    if (form.querySelector("#zc_spmSubmit")) {
      form.querySelector("#zc_spmSubmit").remove();
    }

    // Simulate form submission
    form.submit();

    // Change the button text to 'Subscribed' once the form is submitted
    setButtonText('Subscribed');
    setSignupSuccess(true); // Optionally show a success message
  };

  return (
    <div id="customForm">
      <div className="quick_form_12_css" name="SIGNUP_BODY">
        <div>
          <form
            method="POST"
            id="zcampaignOptinForm"
            action="https://zcsub-cmpzourl.maillist-manage.com/weboptin.zc"
            target="_zcSignup"
            onSubmit={handleSubmit}
          >
            <div className="input-container">
              <input
                type="text" 
                placeholder="email"
                name="CONTACT_EMAIL"
                id="EMBED_FORM_EMAIL_LABEL"
                required={true}
                className="custom-input"
              />
              <input
                type="submit"
                name="SIGNUP_SUBMIT_BUTTON"
                id="zcWebOptin"
                value={buttonText} // Dynamically change button text
                className="custom-button"
                disabled={signupSuccess} // Disable button after success
              />
            </div>

            {/* Hidden input fields */}
            <input type="hidden" id="fieldBorder" value="" />
            <input type="hidden" id="submitType" name="submitType" value="optinCustomView" />
            <input type="hidden" id="emailReportId" name="emailReportId" value="" />
            <input type="hidden" id="formType" name="formType" value="QuickForm" />
            <input type="hidden" name="zx" id="cmpZuid" value="1326d991b" />
            <input type="hidden" name="zcvers" value="3.0" />
            <input type="hidden" name="oldListIds" id="allCheckedListIds" value="" />
            <input type="hidden" id="mode" name="mode" value="OptinCreateView" />
            <input type="hidden" id="zcld" name="zcld" value="1118656a229388f35" />
            <input type="hidden" id="zctd" name="zctd" value="1118656a2293537a9" />
            <input type="hidden" id="document_domain" value="" />
            <input type="hidden" id="zc_Url" value="zcsub-cmpzourl.maillist-manage.com" />
            <input type="hidden" id="new_optin_response_in" value="0" />
            <input type="hidden" id="duplicate_optin_response_in" value="0" />
            <input type="hidden" name="zc_trackCode" id="zc_tr+ackCode" value="ZCFORMVIEW" />
            <input
              type="hidden"
              id="zc_formIx"
              name="zc_formIx"
              value="3z919777c0b61c59d755e8efb02cf11d157ecb03fbd6239fbbfa3338b2891f8b10"
            />
            <input type="hidden" id="viewFrom" value="URL_ACTION" />
            <input type="hidden" id="scriptless" name="scriptless" value="yes" />
            <input type="hidden" id="zc_spmSubmit" name="zc_spmSubmit" value="ZCSPMSUBMIT" />
          </form>
        </div>
      </div>
      <div
        id="zcOptinOverLay"
        style={{
          display: 'none',
          textAlign: 'center',
          backgroundColor: 'rgb(0, 0, 0)',
          opacity: 0.5,
          zIndex: 100,
          position: 'fixed',
          width: '100%',
          top: 0,
          left: 0,
          height: '100vh',
        }}
      ></div>
      <div
        id="zcOptinSuccessPopup"
        style={{
          display: 'none',
          zIndex: 9999,
          width: '800px',
          height: '40%',
          top: '84px',
          position: 'fixed',
          left: '26%',
          backgroundColor: '#FFFFFF',
          borderColor: '#E6E6E6',
          borderStyle: 'solid',
          borderWidth: '1px',
          boxShadow: '0 1px 10px #424242',
          padding: '35px',
        }}
      >
        <span
          style={{ position: 'absolute', top: '-16px', right: '-14px', zIndex: 99999, cursor: 'pointer' }}
          id="closeSuccess"
        >
          <img
            src="https://zcsub-cmpzourl.maillist-manage.com/images/videoclose.png"
            alt="close success popup"
          />
        </span>
        <div id="zcOptinSuccessPanel"></div>
      </div>
    </div>
  );
};

export default ZohoCampaignOptinForm;
