export const errorMessageToDisplay = (
    validator,
    errorName,
    errorValue,
    validationMethod
  ) => {
    return (
      <div className="error_msg_text">
        {validator.message(errorName, errorValue, validationMethod)}
      </div>
    );
  };





  